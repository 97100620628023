/* You can add global styles to this file, and also import other style files */


.fullpageloader {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;

  display: grid;
  align-content: center;
  justify-content: center;

  nz-spin {
    scale: 4;
  }
}
